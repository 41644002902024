/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const adManageRouter = {
  path: '/ad-manage',
  component: Layout,
  redirect: '/ad-manage/ad-list',
  name: 'AdManage',
  meta: {
    title: 'adManage',
    icon: 'international'
  },
  children: [
    {
      path: 'ad-list',
      component: () => import('@/views/ad-manage/switch/list'),
      name: 'AdList',
      meta: { title: 'adList', noCache: true }
    },
    {
      path: 'ad-switch',
      component: () => import('@/views/ad-manage/switch/index'),
      name: 'AdSwitch',
      meta: { title: 'adSwitch', noCache: true }
    },
    {
      path: 'reveal-ad',
      component: () => import('@/views/ad-manage/reveal-ad/index'),
      name: 'RevealAd',
      meta: { title: 'RevealAd', noCache: true }
    },
    {
      path: 'brand-takeover',
      component: () => import('@/views/ad-manage/brand-takeover/index'),
      name: 'BrandTakeover',
      meta: { title: 'brandTakeover', noCache: true }
    }
  ]
}

export default adManageRouter
