import Layout from '@/layout'

const ugcRouter = {
  path: '/ugc',
  component: Layout,
  redirect: '/ugc/audit-list',
  name: 'Ugc',
  meta: {
    title: 'ugc',
    icon: 'eye-open'
  },
  children: [
    {
      path: 'audit-list',
      component: () => import('@/views/ugc-manage/audit/list'),
      name: 'AuditList',
      meta: { title: 'auditList', noCache: false }
    },
    {
      path: 'audit-op/:id',
      component: () => import('@/views/ugc-manage/audit/op'),
      name: 'AuditOp',
      meta: { title: 'auditOp', noCache: false },
      hidden: true
    }
  ]
}

export default ugcRouter
