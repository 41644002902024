/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const crawlerManageRouter = {
  path: '/crawler-manage',
  component: Layout,
  redirect: '/crawler-manage/star-info-list',
  name: 'CrawlerManage',
  meta: {
    title: 'crawlerManage',
    icon: 'chart'
  },
  children: [
    {
      path: 'star-info-list',
      component: () => import('@/views/crawler-manage/crawler/list'),
      name: 'StarInfoList',
      meta: { title: 'starInfoList', noCache: false }
    },
    {
      path: 'star-info',
      component: () => import('@/views/crawler-manage/crawler/starInfo/form'),
      name: 'StarInfoDetail',
      meta: { title: 'starInfoDetail', noCache: false }
    }
  ]
}

export default crawlerManageRouter
