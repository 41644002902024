/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const cashOutRouter = {
  path: '/cash-out',
  component: Layout,
  redirect: '/cash-out/cash-out-manage',
  name: 'CashOut',
  meta: {
    title: 'cashOut',
    icon: 'user'
  },
  children: [
    // 提现管理
    {
      path: 'cash-out-manage',
      component: () => import('@/views/escort-manage/cash-out-manage'),
      name: 'CashOutManage',
      meta: { title: 'cashOutManage', noCache: false }
    }
  ]
}

export default cashOutRouter
