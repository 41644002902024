const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  userId: (state) => state.user.userId,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permission_routes: (state) => state.permission.routes,
  errorLogs: (state) => state.errorLog.logs,
  enums: (state) => state.settings.enums,
  comps: (state) => state.permission.comps,
  mode: (state) => state.permission.mode,
  backend_routes: (state) => state.permission.addRoutes,
  areas: (state) => {
    return [
      {
        id: '-1',
        name: '所有地区'
      },
      ...state.settings.enums.areas
    ]
  }
}
export default getters
