import LoadMore from './loadMore'

const install = function (Vue) {
  Vue.directive('LoadMore', LoadMore)
}

if (window.Vue) {
  window.loadMore = LoadMore
  Vue.use(install) // eslint-disable-line
}

LoadMore.install = install
export default LoadMore
