import Echarts from './echarts'
import WaterMark from './waterMark'
import * as echarts from 'echarts'

const plugins = [Echarts, WaterMark]

export default {
  install: function (Vue, options) {
    Vue.prototype.$echartsAll = echarts
    plugins.forEach((plugin) => {
      Vue.use(plugin, options)
    })
  }
}
