import variables from '@/styles/element-variables.scss'
import defaultSettings from '@/settings'
import { getEnumList } from '@/api/common'
import { queryAreaConfigList, queryAreaConfigAll } from '@/api/dev-manage'
import storage from '@/utils/store'
import { _jsonParse } from '@/utils'

const { showSettings, tagsView, fixedHeader, sidebarLogo } = defaultSettings

const state = {
  theme: variables.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  enums: storage.get('_enums', {})
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  SET_EMUNS: (state, enums) => {
    state.enums = enums
    storage.set('_enums', enums)
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  syncDictionary({ commit }) {
    return new Promise((resolve) => {
      getEnumList().then(async (data) => {
        const constant = {}
        for (let i in data.constants) {
          constant[i] = _jsonParse(data.constants[i])
        }
        delete data.constants
        for (let i in data) {
          constant[i] = data[i].items
        }
        let { ad: adArea, activity: activityArea, configur: configurArea, pay: payArea, rank: rankArea, liveSetting: liveSettingArea, state: stateArea } = await queryAreaConfigAll()
        //console.log(episodeArea, 'hahha');
        const formatArea = (areas = []) => {
          return areas.map((area) => {
            let id = area?.countries.reduce((acc, cur) => {
              return acc.concat(cur.isoCode)
            }, [])
            return {
              ...area,
              id: id.join(','),
              _id: area.id //保留历史id，用于编辑接口
            }
          })
        }
        //console.log(formatArea(episodeArea), 'hahha');
        commit('SET_EMUNS', {
          ...constant,
          adArea: formatArea(adArea),
          activityArea: formatArea(activityArea),
          configurArea: formatArea(configurArea),
          payArea: formatArea(payArea),
          rankArea: formatArea(rankArea),
          liveSettingArea: formatArea(liveSettingArea),
          stateArea: formatArea(stateArea)
          //episodeArea: formatArea(episodeArea)
        })
        resolve()
        //当前配置数据字典的目录
        // import('@/dictionary').then(local => {
        //   commit('SET_EMUNS', Object.assign({}, backend, local))
        // })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
