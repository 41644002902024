import Layout from '@/layout'

const userManageRouter = {
  path: '/user-manage',
  component: Layout,
  redirect: '/user-manage/user-list',
  name: 'UserManage',
  meta: {
    title: 'userManage',
    icon: 'people'
  },
  children: [
    {
      path: 'user-list',
      component: () => import('@/views/user-manage/user/list'),
      name: 'UserList',
      meta: { title: 'userList', noCache: false }
    },
    {
      path: 'user-black-list',
      component: () => import('@/views/user-manage/black-list/index'),
      name: 'UserBlackList',
      meta: { title: 'userBlackList', noCache: false }
    },
    {
      path: 'user-super-white-list',
      component: () => import('@/views/user-manage/super-white-list/index'),
      name: 'UserSuperWhiteList',
      meta: { title: 'userSuperWhiteList', noCache: false }
    },
    {
      path: 'user-label',
      component: () => import('@/views/user-manage/user-label/index'),
      name: 'UserLabel',
      meta: { title: 'userLabel', noCache: false }
    },
    {
      path: 'white-list',
      component: () => import('@/views/user-manage/white-list/index'),
      name: 'WhiteList',
      meta: { title: 'whiteList', noCache: false }
    },
    {
      path: 'user-indicator',
      component: () => import('@/views/user-manage/user-indicator/index'),
      name: 'Indicator',
      meta: { title: 'Indicator', noCache: false }
    },
    {
      path: 'user-indicator',
      component: () => import('@/views/user-manage/user-indicator/index'),
      name: 'Indicator',
      meta: { title: 'Indicator', noCache: false }
    },
    {
      path: 'user-labelSystem',
      component: () => import('@/views/user-manage/labelSystem/list'),
      name: 'LabelSystem',
      meta: { title: 'LabelSystem', noCache: false }
    },
    {
      path: 'label-add',
      component: () => import('@/views/user-manage/labelSystem/add/index.vue'),
      name: 'LabelAdd',
      meta: { title: 'LabelAdd', noCache: true }
    }
  ]
}

export default userManageRouter
