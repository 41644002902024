/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';

const configurManageRouter = {
  path: '/configur-manage',
  component: Layout,
  redirect: '/configur-manage/configur',
  name: 'ConfigurManage',
  meta: {
    title: 'configurManage',
    icon: 'eye-open'
  },
  children: [
    {
      path: 'info-list',
      component: () => import('@/views/configur-manage/info/list'),
      name: 'InfoList',
      meta: { title: 'infoList', noCache: false }
    },
    {
      path: 'configur',
      component: () => import('@/views/configur-manage/configur/index'),
      name: 'Configur',
      meta: { title: 'configur', noCache: false }
    },
    {
      path: 'telegram-manage',
      component: () => import('@/views/configur-manage/telegram/index'),
      name: 'TelegramManage',
      meta: { title: 'telegramManage', noCache: false }
    }
  ]
};

export default configurManageRouter;
