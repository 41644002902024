import store from '@/store'
import { isEmpty } from '@/utils'

function checkPermission(el, binding) {
  const { value } = binding
  if (isEmpty(value)) return false
  const comps = store.getters && store.getters.comps
  const hasPermission = comps.includes(value)
  if (!hasPermission) {
    el.parentNode && el.parentNode.removeChild(el)
  }
}

export default {
  inserted(el, binding) {
    if (store.getters.roles.includes(1)) return false
    checkPermission(el, binding)
  },
  update(el, binding) {
    if (store.getters.roles.includes(1)) return false
    checkPermission(el, binding)
  },
}
