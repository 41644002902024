/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const dataManageRouter = {
  path: '/data-manage',
  component: Layout,
  redirect: '/data-manage/ali',
  name: 'DataManage',
  meta: {
    title: 'dataManage',
    icon: 'star'
  },
  children: [
    {
      path: 'ali',
      component: () => import('@/views/data-manage/ali/index'),
      name: 'Ali',
      meta: { title: 'ali', noCache: false }
    }
  ]
}

export default dataManageRouter
