<template>
  <div id="app">
    <router-view />
    <Sparrow v-if="hasPermission" />
  </div>
</template>

<script>
// import { getToken } from '@/utils/auth' // get token from cookie
import BrowserLogger from 'alife-logger'
export default {
  name: 'App',
  computed: {
    hasPermission() {
      let { comps, roles } = this.$store.getters
      const hasPermission = roles.includes(1) || comps.includes('DevelopManage:sparrow')
      return hasPermission
    }
  },
  created() {
    //if (getToken()) this.$store.dispatch('settings/syncDictionary')
    if (process.env.NODE_ENV == 'production') {
      window.__bl = BrowserLogger.singleton({
        pid: 'a89dbz1x85@198ad84d10f7e3f',
        imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
        // 其他config配置。
        // enableSPA:true,//未开hash模式 不需要开enableSPA
        release: '0.1.0',
        // environment: import.meta.env.MODE === 'prod' ? 'prod' : 'local',
        appType: 'web',
        sendResource: true,
        enableSPA: true,
        enableLinkTrace: true,
        enableConsole: true,
        behavior: true,
        disableHook: false,
        useFmp: true,
        ignore: {
          //https://help.aliyun.com/document_detail/58655.htm?spm=a2c4g.11186623.0.0.3fd8558c25rjVD#sc-ignore
          ignoreErrors: ['ResizeObserver loop limit exceeded']
        }
      })
    }
  },
  mounted() {
    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    })
  }
}
</script>
