/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const serviceManageRouter = {
  path: '/service-manage',
  component: Layout,
  redirect: '/service-manage/service-list',
  name: 'ServiceManage',
  meta: {
    title: 'serviceManage',
    icon: 'peoples'
  },
  children: [
    {
      path: 'feedback-list',
      component: () => import('@/views/service-manage/feedback/list'),
      name: 'FeedbackList',
      meta: { title: 'feedbackList', noCache: true }
    },
    {
      path: 'question-list',
      component: () => import('@/views/service-manage/question/list'),
      name: 'QuestionList',
      meta: { title: 'questionList', noCache: false }
    },
    {
      path: 'question-manage/:id',
      component: () => import('@/views/service-manage/question/manage/list'),
      name: 'QuestionManage',
      meta: { title: 'questionManage', noCache: true },
      hidden: true
    },
    {
      path: 'push-list',
      component: () => import('@/views/service-manage/push/list'),
      name: 'PushList',
      meta: { title: 'pushList', noCache: false }
    },
    {
      path: 'create-push',
      component: () => import('@/views/service-manage/push/create/index'),
      name: 'CreatePush',
      meta: { title: 'createPush', noCache: true },
      hidden: true
    },
    {
      path: 'update-push',
      component: () => import('@/views/service-manage/push/create/index'),
      name: 'UpdatePush',
      meta: { title: 'updatePush', noCache: true },
      hidden: true
    },
    {
      path: 'push-detail',
      component: () => import('@/views/service-manage/push/detail'),
      name: 'PushDetail',
      meta: { title: 'pushDetail', noCache: true },
      hidden: true
    },
    {
      path: 'message-list',
      component: () => import('@/views/service-manage/message/list'),
      name: 'MessageList',
      meta: { title: 'messageList', noCache: false }
    },
    {
      path: 'send-list',
      component: () => import('@/views/service-manage/send/list'),
      name: 'SendList',
      meta: { title: 'sendList', noCache: false }
    },
    {
      path: 'auto-push-list',
      component: () => import('@/views/service-manage/autoPush/list'),
      name: 'AutoPushList',
      meta: { title: 'autoPushList', noCache: false }
    },
    // 邮箱召回
    {
      path: 'email-recall',
      component: () => import('@/views/service-manage/email-recall/list'),
      name: 'EmailRecall',
      meta: { title: 'emailRecall', noCache: false }
    }
  ]
}

export default serviceManageRouter
