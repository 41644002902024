<template>
  <el-dropdown trigger="click" @command="handleSetLanguage">
    <div>
      <svg-icon icon-class="language" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="item of languages" :key="item.value" :disabled="$i18n.locale === item.value" :command="item.value">
        {{ item.label }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'I18nChange',
  data() {
    return {
      languages: [
        {
          label: '简体中文',
          value: 'zh'
        },
        {
          label: 'English',
          value: 'en'
        }
      ]
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleSetLanguage(val) {
      this.$i18n.locale = val
    }
  }
}
</script>

<style scoped>
.screenfull-svg {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;
  width: 20px;
  height: 20px;
  vertical-align: 10px;
}
</style>
