/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const activityManageRouter = {
  path: '/activity-manage',
  component: Layout,
  redirect: '/activity-manage/live-activity/template',
  name: 'ActivityManage',
  meta: {
    title: 'activityManage',
    icon: 'shopping'
  },
  children: [
    {
      path: 'fission',
      component: () => import('@/views/activity-manage/fission-v2/list'),
      name: 'Fission',
      meta: { title: 'fission', noCache: false }
    },
    {
      path: 'promote-list',
      component: () => import('@/views/activity-manage/promote/list'),
      name: 'PromoteList',
      meta: { title: 'promoteList', noCache: false }
    },
    {
      path: 'h5-share',
      component: () => import('@/views/activity-manage/h5-share/list'),
      name: 'H5Share',
      meta: { title: 'h5Share', noCache: false }
    },
    {
      path: 'h5-share-add',
      component: () => import('@/views/activity-manage/h5-share/add/index'),
      name: 'H5ShareAdd',
      meta: { title: 'h5ShareAdd', noCache: false },
      hidden: true
    },
    {
      path: 'add-fission',
      component: () => import('@/views/activity-manage/fission-v2/add/index'),
      name: 'AddFission',
      meta: { title: 'addFission', noCache: false },
      hidden: true
    },
    {
      path: 'set-rule',
      component: () => import('@/views/activity-manage/fission-v2/setRule/index'),
      name: 'SetRule',
      meta: { title: 'setRule', noCache: false },
      hidden: true
    },
    {
      path: 'reward-list',
      component: () => import('@/views/activity-manage/fission-v2/reward/list'),
      name: 'RewardList',
      meta: { title: 'rewardList', noCache: false },
      hidden: true
    },
    {
      path: 'invite-user-page',
      component: () => import('@/views/activity-manage/inviteUserPage/index'),
      name: 'InviteUserPage',
      meta: { title: 'inviteUserPage', noCache: false },
      hidden: true
    },
    // 概率游戏模板
    {
      path: 'probability-game',
      component: () => import('@/views/activity-manage/probabilityGame/index'),
      name: 'ProbabilityGame',
      meta: { title: 'probabilityGame', noCache: false },
      hidden: true
    },
    // 概率游戏模板-游戏设置-币出礼物游戏
    {
      path: 'probability-game/game-setting',
      component: () => import('@/views/activity-manage/probabilityGame/game-setting/index'),
      name: 'ProbabilityGameSetting',
      meta: { title: 'probabilityGameSetting', noCache: false },
      hidden: true
    },
    // 概率游戏模板-游戏设置-币出币游戏
    {
      path: 'probability-game/game-setting1',
      component: () => import('@/views/activity-manage/probabilityGame/game-setting1/index'),
      name: 'ProbabilityGameSetting1',
      meta: { title: 'probabilityGameSetting1', noCache: false },
      hidden: true
    },
    // 概率游戏模板-奖池数值设置
    {
      path: 'probability-game/jackpot-number-setting',
      component: () => import('@/views/activity-manage/probabilityGame/jackpot-number-setting/index'),
      name: 'JackpotNumberSetting',
      meta: { title: 'jackpotNumberSetting', noCache: false },
      hidden: true
    },
    // 概率游戏模板-奖池礼物设置
    {
      path: 'probability-game/jackpot-gift-setting',
      component: () => import('@/views/activity-manage/probabilityGame/jackpot-gift-setting/index'),
      name: 'JackpotGiftSetting',
      meta: { title: 'jackpotGiftSetting', noCache: false },
      hidden: true
    },
    // 概率游戏模板-数据报表
    {
      path: 'probability-game/data-report',
      component: () => import('@/views/activity-manage/probabilityGame/data-report/index'),
      name: 'DataReport',
      meta: { title: 'dataReport', noCache: false },
      hidden: true
    },
    {
      path: 'probability-game/data-report1',
      component: () => import('@/views/activity-manage/probabilityGame/data-report1/index'),
      name: 'DataReport1',
      meta: { title: 'dataReport1', noCache: false },
      hidden: true
    },
    {
      path: 'probability-game/official-setting',
      component: () => import('@/views/activity-manage/probabilityGame/official-setting/index'),
      name: 'OfficialSetting',
      meta: { title: 'officialSetting', noCache: false },
      hidden: true
    },
    // 通行证管理
    {
      path: 'gate-pass-manage',
      component: () => import('@/views/activity-manage/gate-pass/index'),
      name: 'GatePass',
      meta: { title: 'gatePass', noCache: false },
      hidden: true
    },
    // 通行证管理-编辑
    {
      path: 'gate-pass-manage-edit',
      component: () => import('@/views/activity-manage/gate-pass/edit/index'),
      name: 'GatePassEdit',
      meta: { title: 'gatePassEdit', noCache: false }
    },
    // 裂变拉新
    // {
    //   path: 'fission-invite',
    //   component: () => import('@/views/activity-manage/fission-invite/index'),
    //   name: 'FissionInvite',
    //   meta: { title: 'fissionInvite', noCache: false },
    //   hidden: true
    // },
    // 明星pk
    {
      path: 'star-pk',
      component: () => import('@/views/activity-manage/starPk/index'),
      name: 'StarPk',
      meta: { title: 'starPk', noCache: false },
      hidden: true
    },
    // 明星pk-新增|编辑
    {
      path: '/star-pk/add',
      component: () => import('@/views/activity-manage/starPk/add/index'),
      name: 'AddPkStar',
      meta: { title: 'addPkStar', noCache: false },
      hidden: true
    },
    // 明星pk-数据报表
    {
      path: '/star-pk/report',
      component: () => import('@/views/activity-manage/starPk/report/index'),
      name: 'StarPkReport',
      meta: { title: 'starPkReport', noCache: false },
      hidden: true
    },
    // 明星pk-任务奖励设置
    {
      path: '/star-pk/rewardSetting',
      component: () => import('@/views/activity-manage/starPk/rewardSetting/index'),
      name: 'StarPkRewardSetting',
      meta: { title: 'starPkRewardSetting', noCache: false }
    },
    {
      path: 'live-activity/template',
      component: () => import('@/views/activity-manage/live-activity/template/index'),
      name: 'LiveActivityTemplate',
      meta: { title: 'activityTemplate', noCache: false }
    },
    {
      path: 'live-activity/template/add/:temId?',
      component: () => import('@/views/activity-manage/live-activity/template/add'),
      name: 'TemplateAdd',
      meta: { title: 'templateAdd', noCache: true },
      hidden: true
    },
    {
      path: 'live-activity/rank/:temId',
      component: () => import('@/views/activity-manage/live-activity/rank/index'),
      name: 'TemplateRank',
      meta: { title: 'templateRank', noCache: true },
      hidden: true
    },
    {
      path: 'live-activity/rank/add/:temId/:rankId?',
      component: () => import('@/views/activity-manage/live-activity/rank/add'),
      name: 'TemplateRankAdd',
      meta: { title: 'templateRankAdd', noCache: true },
      hidden: true
    },
    {
      path: 'live-activity/reward/:rankId',
      component: () => import('@/views/activity-manage/live-activity/reward/index'),
      name: 'TemplateReward',
      meta: { title: 'templateReward', noCache: true },
      hidden: true
    },
    {
      path: 'live-activity/honor/:temId',
      component: () => import('@/views/activity-manage/live-activity/honor/index'),
      name: 'TemplateHonor',
      meta: { title: 'templateHonor', noCache: true },
      hidden: true
    },
  ]
}

export default activityManageRouter
