import store from '@/store'

const pickerOptions = {
  shortcuts: [
    {
      text: '最近一周',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}

export const hasPermission = function (value) {
  if (store.getters.roles.includes(1)) return true
  const comps = store.getters && store.getters.comps
  return Array.isArray(value) ? value.some((item) => comps.includes(item)) : comps.includes(value)
}

export default {
  install(Vue) {
    const EventBus = new Vue()
    Vue.prototype.pickerOptions = pickerOptions
    Vue.prototype.$enums = store.getters.enums


    Vue.prototype.$hasPermission = hasPermission
    // Vue.prototype.$hasPermission = (value) => {
    //   if (store.getters.roles.includes(1)) return true
    //   const comps = store.getters && store.getters.comps
    //   return Array.isArray(value) ? value.some((item) => comps.includes(item)) : comps.includes(value)
    // }
    Object.defineProperties(Vue.prototype, {
      $bus: {
        get: function () {
          return EventBus
        }
      }
    })
  }
}
