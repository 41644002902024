/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const liveManageRouter = {
  path: '/live-manage',
  component: Layout,
  redirect: '/live-manage/anchor-list',
  name: 'LiveManage',
  meta: {
    title: 'liveManage',
    icon: 'user'
  },
  children: [
    // 主播管理
    {
      path: 'anchor-list',
      component: () => import('@/views/live-manage/anchor-list/index'),
      name: 'AnchorLive',
      meta: { title: 'anchorLive', noCache: false }
    },
    // 主播日结明细
    {
      path: 'day-itemized',
      component: () => import('@/views/live-manage/day-itemized/list'),
      name: 'DayItemized',
      meta: { title: 'dayItemized', noCache: false }
    },
    // 主播月结明细
    {
      path: 'month-itemized',
      component: () => import('@/views/live-manage/month-itemized/list'),
      name: 'MonthItemized',
      meta: { title: 'monthItemized', noCache: false }
    },
    // 公会管理
    {
      path: 'guild',
      component: () => import('@/views/live-manage/guild/index'),
      name: 'guildManage',
      meta: { title: 'guildManage', noCache: false }
    },
    // 开播明细
    {
      path: 'live-detail-list',
      component: () => import('@/views/live-manage/live-detail/index'),
      name: 'LiveDetail',
      meta: { title: 'liveDetail', noCache: false }
    },
    // 处罚记录
    {
      path: 'punish-record',
      component: () => import('@/views/live-manage/punish-record/index'),
      name: 'PunishRecord',
      meta: { title: 'punishRecord', noCache: false }
    },
    // 悬浮窗配置
    {
      path: 'floating-config',
      component: () => import('@/views/live-manage/floating-config/list'),
      name: 'FloatingConfig',
      meta: { title: 'floatingConfig', noCache: false }
    },
    // 直播间管理
    {
      path: 'live-room',
      component: () => import('@/views/live-manage/live-room/index'),
      name: 'LiveRoom',
      meta: { title: 'liveRoom', noCache: false }
    },
    // 游戏开关及道具配置
    {
      path: 'game-switch',
      component: () => import('@/views/live-manage/game-switch/index'),
      name: 'GameSwitch',
      meta: { title: 'gameSwitch', noCache: false }
    },
    // 直播结算
    {
      path: 'live-settlement',
      component: () => import('@/views/live-manage/live-settlement/index'),
      name: 'LiveSettlement',
      meta: { title: 'liveSettlement', noCache: false }
    },
    // 主播推荐池-v2.9.2需求下架
    // {
    //   path: 'anchor-recommend',
    //   component: () => import('@/views/live-manage/anchor-recommend/index'),
    //   name: 'AnchorRecommend',
    //   meta: { title: 'anchorRecommend', noCache: false }
    // },
    // 结算配置
    {
      path: 'income-settings',
      component: () => import('@/views/live-manage/income-settings/index'),
      name: 'IncomeSettings',
      meta: { title: 'incomeSettings', noCache: false }
    },
    {
      //直播间分类列表配置
      path: 'live-room-category',
      component: () => import('@/views/live-manage/live-room-category/index'),
      name: 'LiveRoomCategory',
      meta: { title: 'liveRoomCategory', noCache: false }
    },
    {
      //直播间分类列表配置-分类列表-二级设置
      path: 'live-room-category-sub',
      component: () => import('@/views/live-manage/live-room-category/category/second/list.vue'),
      name: 'LiveRoomCategoryListSecond',
      meta: { title: 'liveRoomCategoryListSecond', noCache: false }
    },
    {
      //退款列表
      path: 'refund-list',
      component: () => import('@/views/live-manage/refund-list/list.vue'),
      name: 'RefundList',
      meta: { title: 'refundList', noCache: false }
    },
    {
      //公会房
      path: 'guild-room',
      component: () => import('@/views/live-manage/guild-room/list.vue'),
      name: 'GuildRoom',
      meta: { title: 'guildRoom', noCache: false }
    },
    {
      //公会房数据报表
      path: 'guild-room-report',
      component: () => import('@/views/live-manage/guild-room/report/list.vue'),
      name: 'GuildRoomReport',
      meta: { title: 'guildRoomReport', noCache: false }
    },
    {
      //公会房指定工会报表
      path: 'guild-room-report-custom',
      component: () => import('@/views/live-manage/guild-room/report/list.vue'),
      name: 'GuildRoomReportCustom',
      meta: { title: 'guildRoomReportCustom', noCache: true, custom: true }
    }
  ]
}

export default liveManageRouter
