import app from '@/main'
import { commonExport } from '@/api/common'
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xdc00 && code <= 0xdfff) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach((v) => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/**
 * handle st  reqParams
 * @param {value}
 * @returns {boolean}
 */
export function isLegalKey(value) {
  return value !== undefined && value !== null && value !== '' && JSON.stringify(value) !== '[]'
}

/**
 *
 */
export function _merge(...args) {
  const obj = Object.assign({}, ...args)
  Object.keys(args[0]).forEach((item) => {
    args[0][item] = obj[item]
  })
}

/*
 * list to Mao
 * @param [{id:1, name: 'name'}]
 * @returns {1: 'name'}
 */
export function list2Map(arr) {
  return arr.reduce(function (map, obj) {
    map[obj.id] = obj.name
    return map
  }, {})
}

/*
 * close current subPage
 * @param currentRoute
 * @returns nuldsdsl
 */
export function closeCurrentPage(view) {
  // const view = this.$route
  console.log('current ciew: ', view)
  app.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
    const latestView = visitedViews.slice(-1)[0]
    if (latestView) {
      app.$router.push(latestView.fullPath)
    } else {
      // now the default is to redirect to the home page if there is no tags-view,
      // you can adjust it according to your needs.
      if (view.name === 'Dashboard') {
        // to reload home page
        app.$router.replace({ path: '/redirect' + view.fullPath })
      } else {
        app.$router.push('/')
      }
    }
  })
}

export function toSeconds(t) {
  var s = 0.0
  if (t) {
    var p = t.split(':')
    for (let i = 0; i < p.length; i++) {
      s = s * 60 + parseFloat(p[i].replace(',', '.'))
    }
  }
  return s
}

/**
 * 截取url最后一位
 */
export function urlLastIndex(url) {
  let index = url.lastIndexOf('/')
  return url.substring(index + 1, index.length)
}

export function _jsonParse(obj) {
  try {
    return JSON.parse(obj)
  } catch (e) {
    return obj
  }
}

export function isEmpty(variable) {
  return variable == null || variable === undefined || variable === ''
}

export function isNotEmpty(variable) {
  return !isEmpty(variable)
}

/**
 * obj 转 querystring
 */
export function objToQueryString(obj = {}) {
  let arr = []
  for (let i in obj) {
    obj[i] && arr.push(`${i}=${obj[i]}`)
  }

  return '?' + arr.join('&')
}

/**
 * 数组切片
 */
export function _group(arr, length) {
  let index = 0,
    newArr = []
  for (let i = 0; i < Math.ceil(arr.length / length); i++) {
    console.log(i)
    newArr.push(arr.slice(index, (index += length)))
  }
  return newArr
}

export function computedLast7Day(date = new Date()) {
  const upDate = parseTime(date, '{y}-{m}-{d}')
  return [parseTime(new Date(upDate).getTime() - 3600 * 1000 * 24 * 7, '{y}-{m}-{d}'), upDate]
}

export function getType(data) {
  let type = Object.prototype.toString.call(data)
  let typeArr = ['String', 'Object', 'Number', 'Array', 'Undefined', 'Function', 'Null', 'Symbol']
  let name
  for (let i = 0; i < typeArr.length; i++) {
    if ('[object ' + typeArr[i] + ']' === type) {
      name = typeArr[i]
    }
  }
  return name
}

export function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*#]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase()
  })
}

export function replaceBreakWithPTag(str, style) {
  if (!str) {
    return ''
  } else {
    style = style || 'style="text-indent: 2em; margin: 5px 0; overflow:hidden;font-size:13px;"'
    str = `<p ${style}>` + str
    return str.replace(/\n|\r/g, `</p><p ${style}>`)
  }
}

export function asyncPool(poolLimit, array, iteratorFn) {
  let i = 0
  const ret = [] // 存储所有的异步任务
  const executing = [] // 存储正在执行的异步任务
  const enqueue = function () {
    if (i === array.length) {
      return Promise.resolve()
    }
    const item = array[i++] // 获取新的任务项
    const p = Promise.resolve().then(() => iteratorFn(item, array).catch((e) => e))
    ret.push(p)

    let r = Promise.resolve()

    // 当poolLimit值小于或等于总任务个数时，进行并发控制
    if (poolLimit <= array.length) {
      // 当任务完成后，从正在执行的任务数组中移除已完成的任务
      const e = p.then(() => executing.splice(executing.indexOf(e), 1))
      executing.push(e)
      if (executing.length >= poolLimit) {
        r = Promise.race(executing)
      }
    }

    // 正在执行任务列表 中较快的任务执行完成之后，才会从array数组中获取新的待办任务
    return r.then(() => enqueue())
  }
  return enqueue().then(() => Promise.all(ret))
}

export function to(promise) {
  return promise.then((data) => [true, data]).catch((err) => [false, err])
}

// export function computedLast7Day(date) {
//   const newDate = date || parseTime(new Date(), '{y}-{m}-{d}')
//   return [parseTime(new Date(newDate).getTime() - 3600 * 1000 * 24 * 7, '{y}-{m}-{d}'), newDate]
// }

export function getJumpUrl(jumpType, url) {
  switch (jumpType) {
    case 'MOVIE': //电影
      return `tiktik://jump/detail?id=${url}&type=0`
    case 'DRAMA': //剧集
      return `tiktik://jump/detail?id=${url}&type=1`
    case 'SHORT': //剧集-短剧
      return `tiktik://jump/detail?id=${url}&type=1&subType=MINISERIES`
    case 'INNER_URL': //app内打开链接
      return `tiktik://jump/webview?url=${encodeURIComponent(url)}`
    case 'OUTER_URL': //手机浏览器打开链接
      return `tiktik://jump/outer?url=${encodeURIComponent(url)}`
    case 'APP_URL': //app页面
      return url
    case 'ALBUM': //片单
      return `tiktik://jump/section/detail?id=${url}`
    case 'STAR': //明星主页
      return `tiktik://jump/actor?id=${url}`
    case 'ROOM': //房间
      return `tiktik://jump/together?roomId=${url}`
    case 'LIVE_ROOM':
      return `tiktik://jump/live?roomNo=${url}`
    case 'TOPIC': //话题
      return `tiktik://jump/topicDetail?topic_id=${url}`
    case 'GROUP': //圈子页面（暂时与明星保持一致，如后续独立 需修改）
      return `tiktik://jump/actor?id=${url}`
    case 'RANKING': //排行榜页面
      return `tiktik://jump/rankList?tabId=${url}`
    default:
      return url
  }
}

export function getFileName(name) {
  return name.substring(0, name.lastIndexOf('.')).trim()
}

// 数组转对象，以其中一个字段为key
export function changeArrToObj(arr = [], field = 'code') {
  const obj = {}
  arr.forEach((item) => {
    obj[item[field]] = item
  })
  return obj
}

/**
 * @description 将秒数转换为hms
 * @param {value} num 秒数
 */
export const formatSecondsHMS = (value) => {
  let secondTime = parseInt(value.toString()) // 秒
  let minuteTime = 0 // 分
  let hourTime = 0 // 小时
  if (secondTime >= 60) {
    minuteTime = parseInt((secondTime / 60).toString())
    secondTime = parseInt((secondTime % 60).toString())
    if (minuteTime >= 60) {
      hourTime = parseInt((minuteTime / 60).toString())
      minuteTime = parseInt((minuteTime % 60).toString())
    }
  }
  let result = '' + (parseInt(secondTime.toString()) > 0 ? parseInt(secondTime.toString()) + 's' : '')

  // if (minuteTime > 0) {
  result = '' + (parseInt(minuteTime.toString()) > 0 ? parseInt(minuteTime.toString()) + 'm' : '') + result
  // }
  // if (hourTime > 0) {
  result = '' + (parseInt(hourTime.toString()) > 0 ? parseInt(hourTime.toString()) + 'h' : '') + result
  // }
  return result
}
/**
 * @description 将秒数转换为时分秒
 * @param {value} num 秒数
 */
export const formatSeconds = (value) => {
  let secondTime = parseInt(value.toString()) // 秒
  let minuteTime = 0 // 分
  let hourTime = 0 // 小时
  if (secondTime >= 60) {
    minuteTime = parseInt((secondTime / 60).toString())
    secondTime = parseInt((secondTime % 60).toString())
    if (minuteTime >= 60) {
      hourTime = parseInt((minuteTime / 60).toString())
      minuteTime = parseInt((minuteTime % 60).toString())
    }
  }
  let result = '' + (parseInt(secondTime.toString()) < 10 ? '0' + parseInt(secondTime.toString()) : parseInt(secondTime.toString()))

  // if (minuteTime > 0) {
  result = '' + (parseInt(minuteTime.toString()) < 10 ? '0' + parseInt(minuteTime.toString()) : parseInt(minuteTime.toString())) + ':' + result
  // }
  // if (hourTime > 0) {
  result = '' + (parseInt(hourTime.toString()) < 10 ? '0' + parseInt(hourTime.toString()) : parseInt(hourTime.toString())) + ':' + result
  // }
  return result
}

export const downloadLink = (url) => {
  window.open(url)
}

/**
 * @description 公共导出方法
 * @param1 -exportType 任务类型
 * @param2 -params 筛选参数
 * @param3 -custom 默认false 统一提示至报表中心下;如需自定义请求成功后的回调,传true，在exportExcel().then中处理逻辑
 * */
export const exportExcel = (exportType, params, custom) => {
  const obj = { exportType, paramJson: JSON.stringify(params || {}), timestamp: new Date().getTime() }
  return new Promise((resolve, reject) => {
    commonExport(obj)
      .then((res) => {
        if (!custom) {
          app.$message.success({
            dangerouslyUseHTMLString: true,
            message: '导出任务已提交，请前往<a href="#/report-center/index" style="color:#6eb3f9"> 报表中心 </a> 查看进度'
          })
        }
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
/**
 * @description 计算两时间间隔是否超过x月
 * @param1 -开始时间
 * @param2 -结束时间
 * @param3 -间隔月份 不传默认为3
 * */
export const compareDate = (star, end, span) => {
  const sDate = new Date(star)
  const eDate = new Date(end)

  if (eDate.getFullYear() - sDate.getFullYear() > 1) {
    //比较年
    return true
  } else if (eDate.getMonth() - sDate.getMonth() > (span || 3)) {
    //比较月
    return true
  } else if (eDate.getMonth() - sDate.getMonth() == (span || 3)) {
    if (eDate.getDate() - sDate.getDate() >= 1) {
      return true
    }
  } else if (eDate.getFullYear() - sDate.getFullYear() == 1) {
    if (eDate.getMonth() + 12 - sDate.getMonth() > (span || 3)) {
      return true
    } else if (eDate.getMonth() + 12 - sDate.getMonth() == (span || 3) && eDate.getDate() - sDate.getDate() >= 1) {
      return true
    }
  }
  return false
}
/**
 * @description 获取某个自然月有多少天
 * @param1 -年
 * @param2 -月(参数从 0 开始，月份需要减1)
 * */
export const getDaysInMonth = (year, month) => {
  // month 参数从 0 开始，因此需要减去 1
  const date = new Date(year, month + 1, 0)
  return date.getDate()
}
/**
 * @description 获取当前日期往前n个月的日期 返回值 例：2024-3-21
 * @param1 -间隔月份
 * */
export const getPreviousNMonths = (n) => {
  const edate = new Date()
  const year = edate.getFullYear() //当前年份
  const month = edate.getMonth() //当前月份
  const day = edate.getDate() //当前日期
  let targetY
  let targetM
  let targetD
  const previousYear = n > 12 ? Math.floor(n / 12) : 0 //如间隔超出一年，额外处理
  const previousMonth = n > 12 ? n % 12 : n //如间隔超出一年，额外处理
  targetY = year - previousYear
  const tempM = month + 1 - previousMonth
  if (tempM <= 0) targetY-- //如扣除整年月份后 月份差额仍跨年
  targetM = tempM <= 0 ? 11 + tempM : month - previousMonth
  const targetDayNum = getDaysInMonth(targetY, targetM)
  targetD = targetDayNum < day ? targetDayNum - (day - targetDayNum) : day //如目标月份无当前日期，向前倒推天数差额
  const targetDate = `${targetY}-${targetM + 1 === 0 ? 12 : targetM + 1}-${targetD}`
  return targetDate
}
/**
 * @description 获取当前日期往后n个月的日期 返回值 例：2024-3-21
 * @param1 -间隔月份
 * */
export const getAfterNMonths = (str, n) => {
  const edate = new Date(str)
  const year = edate.getFullYear() //当前年份
  const month = edate.getMonth() //当前月份
  const day = edate.getDate() //当前日期
  let targetY
  let targetM
  let targetD
  const previousYear = n > 12 ? Math.floor(n / 12) : 0 //如间隔超出一年，额外处理
  const previousMonth = n > 12 ? n % 12 : n //如间隔超出一年，额外处理
  targetY = year + previousYear
  const tempM = month + 1 + previousMonth - 12 // 月份之和与12个月的差值
  if (tempM > 0) targetY++ //如增加整年月份后 月份差额仍跨年
  targetM = tempM > 0 ? tempM - 1 : month + previousMonth
  const targetDayNum = getDaysInMonth(targetY, targetM)
  if (targetDayNum < day) {
    targetD = day - targetDayNum //如目标月份无当前日期，向后追加天数差额
    targetM = targetM + 1
  } else {
    targetD = day
  }
  const targetDate = `${targetY}-${targetM + 1 === 0 ? 12 : targetM + 1}-${targetD}`
  return targetDate
}
