/* eslint-disable */
const echarts = equire([
  // 写上你需要的
  'bar',
  'line',
  'pie',
  'radar',
  'legend',
  'title',
  'markLine',
  'dataZoom',
  'graphic',
])

export default {
  install: function (Vue) {
    Vue.prototype.$echarts = echarts
  },
}

// 接下来的使用就跟之前一样，初始化图表，设置配置项
// var myChart = echarts.init(document.getElementById('main'));
// myChart.setOption({
//   // ...
// });
