/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const activityManageRouter = {
  path: '/cover-label-manage',
  component: Layout,
  redirect: '/cover-label-manage/label-config',
  name: 'LabelConfig',
  // meta: {
  //   title: 'activityManage',
  //   icon: 'shopping'
  // },
  children: [
    {
      path: 'label-config',
      component: () => import('@/views/cover-label-manage/label-config'),
      name: 'LabelConfig',
      meta: { title: 'labelConfig', noCache: false }
    },
    {
      path: 'label-send',
      component: () => import('@/views/cover-label-manage/label-send'),
      name: 'LabelSend',
      meta: { title: 'labelSend', noCache: false }
    },
  ]
}

export default activityManageRouter
