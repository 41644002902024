import { asyncRoutes, constantRoutes } from '@/router'
import { getAuthorizeResource } from '@/api/auth-manage'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: [],
  comps: [],
  mode: 'normal'
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_COMP: (state, comps) => {
    //state.routes = constantRoutes.concat(routes)
    state.comps = comps
  },
  SET_MODE: (state, mode) => {
    state.mode = mode
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise((resolve) => {
      let accessedRoutes = []
      if (roles.includes(1)) {
        accessedRoutes = asyncRoutes || []
        commit('SET_ROUTES', accessedRoutes)
        resolve(asyncRoutes)
      } else {
        getAuthorizeResource().then((data) => {
          console.log(data, 'heheda')
          let { elements, menus } = data
          accessedRoutes = transferMenu(menus)

          commit(
            'SET_COMP',
            elements.map((item) => item.code)
          )
          commit('SET_ROUTES', accessedRoutes)
          resolve(asyncRoutes)
        })
      }
    })
  }
}

function transferMenu(menuList) {
  for (let item of menuList) {
    if (item.code == 'Dashboard') item.hidden = true
    item.path = item.url || ''
    item.meta = {
      title: item.title,
      icon: item.icon
    }
    item.children = transferMenu(item.childMenu || [])
  }
  return menuList
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
