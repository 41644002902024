/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const escortManageRouter = {
  path: '/escort-manage',
  component: Layout,
  redirect: '/escort-manage/user-list',
  name: 'EscortManage',
  meta: {
    title: 'escortManage',
    icon: 'shopping'
  },
  children: [
    {
      path: 'user-list',
      component: () => import('@/views/escort-manage/user-list'),
      name: 'UserList',
      meta: { title: 'UserList', noCache: false }
    },
    {
      path: 'settings',
      component: () => import('@/views/escort-manage/settings'),
      name: 'Settings',
      meta: { title: 'Settings', noCache: false }
    },
    {
      path: 'report-list',
      component: () => import('@/views/escort-manage/report/list'),
      name: 'EscortReportList',
      meta: { title: 'escortReportList', noCache: false }
    },
    {
      path: 'order-list',
      component: () => import('@/views/escort-manage/order/index'),
      name: 'EscortOrderList',
      meta: { title: 'escortOrderList', noCache: false }
    },
    {
      path: 'divide-ratio',
      component: () => import('@/views/escort-manage/divide-ratio/index'),
      name: 'DivideRatio',
      meta: { title: 'divideRatio', noCache: false }
    }
  ]
}

export default escortManageRouter
