import Layout from '@/layout'

const userGroupRouter = {
  path: '/user-group',
  component: Layout,
  redirect: '/user-group/new-group',
  name: 'userGroup',
  meta: {
    title: 'userGroup',
    icon: 'people'
  },
  children: [
    {
      path: 'new-group',
      component: () => import('@/views/user-group/new/index'),
      name: 'NewGroup',
      meta: { title: 'newGroup', noCache: false }
    },
    {
      path: 'manage',
      component: () => import('@/views/user-group/manage/index'),
      name: 'manage',
      meta: { title: 'groupManage', noCache: false }
    }
  ]
}

export default userGroupRouter
