<template>
  <template-dialog-wraper title="修改密码" @submit="handleSubmit">
    <el-form ref="ef" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="旧密码" prop="oldPassWord">
        <el-input v-model="form.oldPassWord" placeholder="请输入旧密码" show-password />
      </el-form-item>
      <el-form-item label="新密码" prop="newPassWordFirst">
        <el-input v-model="form.newPassWordFirst" placeholder="请输入新密码" show-password />
      </el-form-item>
      <el-form-item label="确认新密码" prop="newPassWord">
        <el-input v-model="form.newPassWord" placeholder="请确认新密码" show-password />
      </el-form-item>
      <div class="tip">密码规则： 至少包含一个小写字母、一个大写字母、一个数字和一个特殊字符 @#$%^&+=!</div>
    </el-form>
  </template-dialog-wraper>
</template>

<script>
import { updatePwd } from '@/api/common'
import { isEmpty } from '@/utils'
export default {
  data() {
    return {
      form: {
        oldPassWord: '',
        newPassWordFirst: '',
        newPassWord: ''
      },
      rules: {
        oldPassWord: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPassWordFirst: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        newPassWord: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (isEmpty(value)) {
                callback(new Error('请确认新密码'))
              }
              if (value != this.form.newPassWordFirst) {
                callback(new Error('两次密码输入不一致'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.ef.validate((valid) => {
        console.log(valid)
        if (valid) {
          updatePwd({
            accountId: this.$store.getters.userId,
            newPassWord: this.form.newPassWord,
            oldPassWord: this.form.oldPassWord
          }).then(() => {
            this.$message.success('密码修改成功')
            this.$parent.$emit('submit')
            this.$emit('close')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.tip {
  font-size: 12px;
  text-align: center;
}
</style>
