import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/auth/backend/account/login',
    method: 'post',
    data
  })
}

export function getInfo(params) {
  return request({
    url: '/auth/backend/account/info',
    method: 'get',
    params
  })
}

export function logout() {
  return request({
    url: '/auth/backend/account/logout',
    method: 'put',
    loading: true
  })
}

export function allUserLogout() {
  return request({
    url: '/auth/backend/account/all_user_logout',
    method: 'put',
    loading: true
  })
}

export function queryUserDetail(params) {
  return request({
    url: '/user/backend/detail',
    method: 'get',
    params,
    loading: true
  })
}

export function updateUserInfo(data) {
  return request({
    url: '/user/backend/info/edit',
    method: 'post',
    data,
    loading: true
  })
}

export function resetPortrait(data) {
  return request({
    url: '/user/backend/info/resetPortrait',
    method: 'post',
    data,
    loading: true
  })
}

export function getUserPunishByUserId(params) {
  return request({
    url: '/user/behavior/backend/feedback/report/user/getPunishByUserId',
    method: 'put',
    params,
    loading: true
  })
}

export function punishUserReport(data) {
  return request({
    url: '/user/behavior/backend/feedback/report/user/punishByUserPage',
    method: 'put',
    data,
    loading: true
  })
}

// 用户列表-查询功能状态
export function getAccountStatus(data) {
  return request({
    url: '/user/backend/monitor/getAccountStatus',
    method: 'post',
    data
  })
}

// 用户列表-修改支付、提现状态
export function changeAccountStatus(data) {
  return request({
    url: '/user/backend/monitor/account/disabled',
    method: 'post',
    data
  })
}
// 用户黑名单 新增
export function saveBlacklist(data) {
  return request({
    url: '/user/backend/blacklist/save',
    method: 'post',
    data,
    loading: true
  })
}
// 用户黑名单 删除
export function delBlacklist(id) {
  return request({
    url: `/user/backend/blacklist/delete/${id}`,
    method: 'post'
  })
}

//启用或者禁用标签配置
export function enableUser(data) {
  return request({
    url: `/user/backend/tag/config/enable`,
    method: 'post',
    data,
    loading: true
  })
}

//编辑标签
export function updateUser(data) {
  return request({
    url: `/user/backend/tag/config/update`,
    method: 'post',
    data,
    loading: true
  })
}

export function whitelistDetail() {
  return request({
    url: '/user/backend/whitelist/detail',
    method: 'get',
    loading: true
  })
}

export function whitelistUpdate(data) {
  return request({
    url: `/user/backend/whitelist/update`,
    method: 'post',
    data,
    loading: true
  })
}

export function whitelistRemove(params) {
  return request({
    url: '/user/backend/whitelist/remove',
    method: 'get',
    params,
    loading: true
  })
}

// 编辑用户标签指标
export function editLabelCondition(data) {
  return request({
    url: `/rule/engine/backend/user/rule/edit`,
    method: 'post',
    data,
    loading: true
  })
}

// 编辑用户标签指标
export function addLabelCondition(data) {
  return request({
    url: `/rule/engine/backend/user/rule/add`,
    method: 'post',
    data,
    loading: true
  })
}

// 开启/关闭用户标签指标
export function enableLabelCondition(data) {
  return request({
    url: `/rule/engine/backend/user/rule/toggle`,
    method: 'post',
    data,
    loading: true
  })
}

// 新增用户指标类型
export function saveLabeClassify(data) {
  return request({
    url: `/rule/engine/backend/user/category/save`,
    method: 'post',
    data,
    loading: true
  })
}

// 标签分类
export function queryConditionClassification() {
  return request({
    url: '/rule/engine/backend/user/category/all',
    method: 'get',
    loading: true
  })
}

// 查询基础属性下拉数据

export function queryBasicAttributes(params) {
  return request({
    url: '/rule/engine/backend/user/rule/selectBasicOptions',
    method: 'get',
    params,
    loading: true
  })
}
// 查询行为属性下拉数据
export function queryBehaviorAttributes(params) {
  return request({
    url: '/rule/engine/backend/user/rule/selectBehaviorOptions',
    method: 'get',
    params,
    loading: true
  })
}

// 查询产品列表
export function queryProductSetting(params) {
  return request({
    url: '/commodity/backend/product/settingByPage',
    method: 'get',
    params,
    loading: true
  })
}

// 新增用户标签
export function addUserTags(data) {
  return request({
    url: `/tag/backend/user/save`,
    method: 'post',
    data,
    loading: true
  })
}

// 编辑用户标签
export function editUserTags(data) {
  return request({
    url: `/tag/backend/user/update`,
    method: 'post',
    data,
    loading: true
  })
}

// 启用/禁用用户标签
export function enableUserTag(data) {
  return request({
    url: `/tag/backend/user/enable`,
    method: 'post',
    data,
    loading: true
  })
}

// 编辑用户标签
export function deleteUserTag(id) {
  return request({
    url: `/tag/backend/user/delete/${id}`,
    method: 'delete',
    loading: true
  })
}

// 启用/禁用用户标签
export function updateUserTagScene(data) {
  return request({
    url: `/tag/backend/user/updateScene`,
    method: 'post',
    data,
    loading: true
  })
}

// 获取用户标签详情
export function queryUserTagDetail(id) {
  return request({
    url: `/tag/backend/user/detail/${id}`,
    method: 'get',
    loading: true
  })
}

// 查询标签覆盖用户数
export function qeuryUserTagsCoverPeopleNum(data) {
  return request({
    url: `/tag/backend/user/estimate`,
    method: 'post',
    data,
    loading: true
  })
}

// 获取用户标签详情
export function queryVersionAll() {
  return request({
    url: `config/manage/versions/page`,
    method: 'get',
    loading: true
  })
}

export function superWhitelist_save(data) {
  return request({
    url: `/user/backend/superWhitelist/save`,
    method: 'post',
    data,
    loading: true
  })
}

export function superWhitelist_delete(id) {
  return request({
    url: `/user/backend/superWhitelist/delete/${id}`,
    method: 'post',
    loading: true
  })
}
// 获取用户标签详情
export function queryMovieId(params) {
  return request({
    url: `/cms/backend/content/getDramaOrMovieById`,
    method: 'get',
    params
  })
}

