/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const vipManageRouter = {
  path: '/vip-manage',
  component: Layout,
  redirect: '/vip-manage/vip-product',
  name: 'VipManage',
  meta: {
    title: 'vipManage',
    icon: 'star'
  },
  children: [
    {
      path: 'vip-detail',
      component: () => import('@/views/vip-manage/detail/index'),
      name: 'VipDetail',
      meta: { title: 'vipDetail', noCache: false }
    },
    {
      path: 'vip-product',
      component: () => import('@/views/vip-manage/product/index'),
      name: 'VipProduct',
      meta: { title: 'vipProduct', noCache: false }
    },
    {
      path: 'vip-discount-case/:id',
      component: () => import('@/views/vip-manage/product/goods-setting/discount-case'),
      name: 'VipDiscountCase',
      meta: { title: 'vipDiscountCase', noCache: false }
    },
    {
      path: 'vip-issue',
      component: () => import('@/views/vip-manage/issue/index'),
      name: 'VipIssue',
      meta: { title: 'vipIssue', noCache: false }
    },
    {
      path: 'vip-activity-config',
      component: () => import('@/views/vip-manage/activity-config/index'),
      name: 'VipActivityConfig',
      meta: { title: 'vipActivityConfig', noCache: false }
    },
    {
      path: 'vip-code',
      component: () => import('@/views/vip-manage/code/list'),
      name: 'VipCode',
      meta: { title: 'vipCode', noCache: false }
    },
    {
      path: 'vip-code-detail/:id',
      component: () => import('@/views/vip-manage/code/detail/list'),
      name: 'VipCodeDetail',
      meta: { title: 'vipCodeDetail', noCache: false },
      hidden: true
    },
    {
      path: 'vip-goods/:marketId',
      component: () => import('@/views/vip-manage/product/page-setting/goods/list'),
      name: 'VipGoods',
      meta: { title: 'vipGoods', noCache: false },
      hidden: true
    },
    {
      path: 'vip-configuration',
      component: () => import('@/views/vip-manage/configuration'),
      name: 'VipConfiguration',
      meta: { title: 'vipConfiguration', noCache: false }
    },
    {
      path: 'vip-configuration-manage/:id',
      component: () => import('@/views/vip-manage/configuration-manage'),
      name: 'VipConfigurationManage',
      meta: { title: 'vipConfigurationManage', noCache: false }
    },
    {
      path: 'vip-manage',
      component: () => import('@/views/vip-manage/configuration'),
      name: 'VipConfiguration',
      meta: { title: 'vipConfiguration', noCache: false }
    },
    {
      path: 'vip-order',
      component: () => import('@/views/vip-manage/order'),
      name: 'VipOrder',
      meta: { title: 'vipOrder', noCache: false }
    },
    {
      path: 'vip-subscription',
      component: () => import('@/views/vip-manage/subscription'),
      name: 'VipSubscription',
      meta: { title: 'vipSubscription', noCache: false }
    }
  ]
}

export default vipManageRouter
