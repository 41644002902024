import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  userId: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((data) => {
          //const data = response
          commit('SET_TOKEN', data)
          setToken(data)
          //return dispatch('settings/syncDictionary', null, { root: true })
          resolve()
        })
        // .then(() => {
        //   resolve()
        // })
        .catch((error) => {
          reject(error)
        })
      // commit('SET_TOKEN', 'admin-token')
      // setToken('admin-token')
      // resolve()
      // if (username.trim() == 'loklok2021' && password == 'Oversea2021') {
      //   commit('SET_TOKEN', 'admin-token')
      //   setToken('admin-token')
      //   resolve()
      // } else {
      //   reject('账号密码错误！')
      // }
    })
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((data) => {
          //const data = response

          if (!data) {
            reject('Verification failed, please Login again.')
          }

          const { roleIds: roles, realName: name, id } = data

          // roles must be a non-empty array
          if (!roles || roles.length <= 0) {
            reject('getInfo: roles must be a non-null array!')
          }
          commit('SET_ROLES', roles)
          commit('SET_NAME', name)
          commit('SET_USER_ID', id)
          commit('SET_AVATAR', 'https://static.netpop.app/img/loklok-1024x1024.png')
          commit('SET_INTRODUCTION', '')
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      // commit('SET_ROLES', ['admin'])
      // commit('SET_NAME', 'Super Admin')
      // commit(
      //   'SET_AVATAR',
      //   'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
      // )
      // commit('SET_INTRODUCTION', 'I am a super administrator')
      // resolve({
      //   roles: ['admin'],
      //   introduction: 'I am a super administrator',
      //   avatar:
      //     'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
      //   name: 'Super Admin'
      // })
    })
  },

  // user logout
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      // commit('SET_TOKEN', '')
      // commit('SET_ROLES', [])
      // removeToken()
      // resetRouter()
      // resolve()
      logout()
        .then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('permission/SET_COMP', [], { root: true })
          removeToken()
          resetRouter()

          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          dispatch('tagsView/delAllViews', null, { root: true })

          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true
    })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
