import Drag from './drag'
import Permission from './permission/permission'

export default {
  install(Vue) {
    Vue.directive('drag', Drag)
    Vue.directive('permission', Permission)
    Vue.directive('focus', {
      inserted(el) {
        el.querySelector('input').focus()
      }
    })
  }
}
