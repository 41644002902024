<template lang="pug">
.asyncButton
  el-button(v-bind='$attrs', @click='handleClick', :loading='loading')
    slot
</template>

<script>
export default {
  name: 'AsyncButton',
  props: {
    method: {
      type: Function,
      default: () => {
        return new Promise((resolve) => {
          resolve()
        })
      }
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async handleClick() {
      this.loading = true
      await this.method()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.asyncButton {
  display: inline-block;
}
.asyncButton + .el-button {
  margin-left: 10px;
}
.el-button + .asyncButton {
  margin-left: 10px;
}
.asyncButton + .asyncButton {
  margin-left: 10px;
}
</style>
