import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element, { Table } from 'element-ui'
import './styles/element-variables.scss'
// import enLang from 'element-ui/lib/locale/lang/en' // 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
import i18n from './lang'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

// import RrComponents from './rr-components'

import VModal from 'vue-js-modal'

import Directive from './directive'

import AsyncButton from './components/AsyncButton'

import Plugins from './plugins'

import GlobalApi from './globalApi'

import rrVue2Elem from 'rr-vue2-elem'

import { getToken } from '@/api/qiniu'

import LoadMore from './directive/load-more'

import './styles/element-variables.scss'
// import { mockXHR } from '../mock';
// mockXHR();

VModal.rootInstance = App

window.qiniu = { getToken }

// Vue.use(VModal, { dialog: true, dynamic: true })
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: {
    adaptive: true,
    clickToClose: false,
    resizable: false,
    scrollable: true,
    height: 'auto'
  }
})

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock');
//   mockXHR();
// }

if (process.env.NODE_ENV === 'development') {
  const { mockXHR } = require('../mock') // simulation data
  mockXHR()
}

Vue.use(Directive)

// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const fixElTableErr = (table) => {
  const oldResizeListener = table.methods.resizeListener
  table.methods.resizeListener = function () {
    window.requestAnimationFrame(oldResizeListener.bind(this))
  }
}

// 一定要在Vue.use之前执行此函数
fixElTableErr(Table)

Vue.use(Element, {
  size: Cookies.get('size') || 'small', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

// Vue.use(RrComponents)
Vue.use(rrVue2Elem)
Vue.use(Plugins)
Vue.use(GlobalApi)
Vue.use(LoadMore)

Vue.component('async-button', AsyncButton)
// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App)
})

export default app
