/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const developManageRouter = {
  path: '/develop-manage',
  component: Layout,
  redirect: '/develop-manage/dic-list',
  name: 'DevelopManage',
  meta: {
    title: 'developManage',
    icon: 'bug',
  },
  children: [
    {
      path: 'dic-list',
      component: () => import('@/views/develop-manage/dic/index'),
      name: 'DicList',
      meta: { title: 'dicList', noCache: false },
    },
    {
      path: 'region-list',
      component: () => import('@/views/develop-manage/region/index'),
      name: 'RegionList',
      meta: { title: 'regionList', noCache: false },
    },
    {
      path: 'https://netpop.yuque.com/staff-arch0a',
      meta: { title: 'yuque' },
    },
  ],
}

export default developManageRouter
