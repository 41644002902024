/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const shortManageRouter = {
  path: '/short-manage',
  component: Layout,
  redirect: '/short-manage/sharing',
  name: 'ShortManage',
  meta: {
    title: 'shortManage',
    icon: 'user'
  },
  children: [
    // 剧付费管理
    {
      path: 'episode-pay',
      component: () => import('@/views/short-manage/episode-pay'),
      name: 'EpisodePay',
      meta: { title: 'episodePay', noCache: false }
    },
    // 剧付费明细
    {
      path: 'episode-buy-itemized',
      component: () => import('@/views/short-manage/episode-buy-itemized'),
      name: 'EpisodeBuyItemized',
      meta: { title: 'episodeBuyItemized', noCache: false }
    },
    // 分成管理
    {
      path: 'sharing',
      component: () => import('@/views/short-manage/sharing/index'),
      name: 'Sharing',
      meta: { title: 'sharing', noCache: false }
    },
    // 提现管理
    {
      path: 'withdraw',
      component: () => import('@/views/short-manage/withdraw/index'),
      name: 'Withdraw',
      meta: { title: 'withdrawInShort', noCache: false }
    },
    // shorts导航栏配置
    {
      path: 'navigation-config',
      component: () => import('@/views/short-manage/navigation-config/index'),
      name: 'ShortsNavigationConfig',
      meta: { title: 'shortsNavigationConfig', noCache: false }
    },
    {
      path: 'page-setting/:navigationId',
      component: () => import('@/views/short-manage/navigation-config/page-setting/list'),
      name: 'ShortPageSetting',
      props: true,
      meta: {
        title: 'shortPageSetting',
        noCache: false,
        custom: true
      },
      hidden: true
    },
    // 找剧组件配置
    {
      path: 'search-drama-config',
      component: () => import('@/views/short-manage/search-drama-config/index'),
      name: 'ShortsSearchDramaConfig',
      meta: { title: 'shortsSearchDramaConfig', noCache: false }
    },
    //找剧标签推荐池
    {
      path: 'search-drama-label-recommendation',
      component: () => import('@/views/short-manage/search-drama-label-recommendation/index'),
      name: 'SearchDramaLabelRecommendation',
      meta: { title: 'searchDramaLabelRecommendation', noCache: false }
    },
    {
      path: 'recommendation-content',
      component: () => import('@/views/short-manage/search-drama-label-recommendation/content/list'),
      name: 'RecommendationContent',
      hidden: true,
      meta: {
        title: 'recommendationContent',
        noCache: true
      }
    }
  ]
}

export default shortManageRouter
